import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { VectorMap } from "react-jvectormap";

// reactstrap components
import {
  // Row,
  // Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  // Container,
  // Card,
  // CardHeader,
  // CardFooter,
  // CardBody
} from "reactstrap";
// import SimpleMap from "./SimpleMap";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

let mapData = {
  AU: 760,
  BR: 550,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  CA: 2000,
  NP: 3000,
  DE: 1300,
  FR: 540,
  GB: 690,
  QA: 550,
  US: 1000,
  HK: 5000,
  ZA: 4000,
  CH: 2200,
  AT: 3200,
  NL: 3500,
  ES: 2800,
  IT: 4200,
  TH: 2500,
  AE: 1800,
};

function Jvectormap(props) {
  return (
    <>
      <VectorMap
        containerClassName="vector-map"
        containerStyle={{
          width: "100%",
          height: "500px",
        }}
        map={"world_mill"}
        tap={false}
        zoomOnScroll={false}
        panOnDrag={false}
        updateSize
        scaleColors={["#f00", "#0071A4"]}
        normalizeFunction="polynomial"
        hoverOpacity={0.7}
        hoverColor={false}
        backgroundColor="transparent"
        markersSelectable={true}
        markersSelectableOne={true}
        regionStyle={{
          initial: {
            fill: "#e9ecef",
            "fill-opacity": 0.8,
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 1,
          },
          hover: {
            fill: "#dee2e6",
            "fill-opacity": 0.8,
            cursor: "pointer",
          },
          selected: {
            fill: "yellow",
          },
        }}
        markerStyle={{
          initial: {
            fill: "#fb6340",
            "stroke-width": 0,
            r: 8,
          },
          hover: {
            fill: "#11cdef",
            "stroke-width": 0,
          },
          selected: { fill: "#fb6340", "stroke-width": 0, r: 12 },
        }}
        markers={props.map}
        series={{
          regions: [
            {
              values: mapData,
              scale: ["#ced4da", "#adb5bd"],
              normalizeFunction: "polynomial",
            },
          ],
        }}
      />
    </>
  );
}

// core components

function Location() {
  const classes = useStyles();
  const [pills, setPills] = React.useState("1");
  const [map, setmap] = React.useState("north_america_mill");

  return (
    <>
      <div className={classes.root}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={6} md={8}>
            {map === "africa_mill" ? (
              <Jvectormap
                map={[
                  {
                    latLng: [-33.92, 18.43],
                    name: "Cape Town",
                  },
                ]}
              />
            ) : (
              ""
            )}
            {map === "north_america_mill" ? (
              <Jvectormap
                map={[
                  {
                    latLng: [43.65, -79.38],
                    name: "Toronto",
                  },
                ]}
              />
            ) : (
              ""
            )}
            {map === "asia_mill" ? (
              <Jvectormap
                map={[
                  {
                    latLng: [27.71, 85.32],
                    name: "Kathmandu",
                  },
                ]}
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Grid>
              <div className="nav-align-center">
                <i
                  className="fas fa-envelope fa-2x"
                  // style={{ color: "#fe9200" }}
                  style={{ color: "#f96332" }}
                />
                <br />
                <br />
                <br />
                <h5>info@kachilagroup.com</h5>
                <br />
                <br />

                <i
                  className="fas fa-map-marker-alt fa-2x"
                  // style={{ color: "#fe9200" }}
                  style={{ color: "#f96332" }}
                />
                <div className="space-40" />
                <TabContent className="gallery" activeTab={"pills" + pills}>
                  <TabPane tabId="pills1">
                    <h5>King West, Toronto, Canada</h5>
                  </TabPane>
                </TabContent>
                <TabContent className="gallery" activeTab={"pills" + pills}>
                  <TabPane tabId="pills2">
                    <h5>City Bowl, Cape Town, South Africa</h5>
                  </TabPane>
                </TabContent>
                <TabContent className="gallery" activeTab={"pills" + pills}>
                  <TabPane tabId="pills3">
                    <h5>Putali Sadak, Kathmandu, Nepal</h5>
                  </TabPane>
                </TabContent>
              </div>

              <div className="nav-align-center">
                <Nav className="nav-pills-primary " pills role="tablist" tabs>
                  <NavItem>
                    <NavLink
                      className={pills === "1" ? "active" : ""}
                      href="#"
                      onClick={() => {
                        setPills("1");
                        setmap("north_america_mill");
                      }}
                    >
                      YYZ
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={pills === "2" ? "active" : ""}
                      outline="true"
                      type=""
                      color="primary"
                      href="#"
                      onClick={() => {
                        setPills("2");
                        setmap("africa_mill");
                      }}
                    >
                      CPT
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={pills === "3" ? "active" : ""}
                      outline="true"
                      type=""
                      color="primary"
                      href="#"
                      onClick={() => {
                        setPills("3");
                        setmap("asia_mill");
                      }}
                    >
                      KTM
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {/* <Row style={{ marginTop: "15vh" }} /> */}
    </>
  );
}

export default Location;
