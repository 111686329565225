import React from "react";
import Firebase from "firebase";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form } from "reactstrap";

export default function FormDialog(props) {
  const [message, setmessage] = React.useState("");

  const handleClose = () => {
    props.set(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    let uid = props.item;
    const id = new Date().getTime().toString();
    const authid = Firebase.auth().currentUser.uid;
    await Firebase.database()
      .ref("/report")
      .push({ id, authid, message, uid });
    setmessage("");
    handleClose();
  };

  return (
    <div>
      <Dialog open={props.open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Report Item</DialogTitle>
        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Please message us your concern with this item.
            </DialogContentText>
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              label="Reason"
              type="text"
              value={message}
              onChange={e => setmessage(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}
