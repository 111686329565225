import React from "react";
import "./ProductTile.scss";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "../Typography";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
// import Card from "@material-ui/core/Card";
// import { Container } from "@material-ui/core";

const styles = theme => ({
  root: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  },
  images: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexWrap: "wrap"
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "38vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100
    },
    "&:hover": {
      zIndex: 1
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15
    },
    "&:hover $imageMarked": {
      opacity: 0
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor"
    }
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%"
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity")
  }
});

function ProductCategories(props) {
  const { classes } = props;

  const [expanded, setExpanded] = React.useState(false);

  function handleExpand() {
    setExpanded(!expanded);
  }

  return (
    <div className={classes.root} component="section">
      <div className="product_tile">
        <div className={classes.images}>
          <ButtonBase
            key={"Aerospace"}
            className={classes.imageWrapper}
            style={{
              width: "100%"
            }}
            onClick={handleExpand}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${props.image})`
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                {props.title}
                <div className={classes.imageMarked} />
              </Typography>
            </div>
          </ButtonBase>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography style={{ textAlign: "justify" }} paragraph></Typography>
            <div style={{ textAlign: "justify" }}>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.description1}
              </Typography>
              <br />
              <Typography variant="body2" color="textSecondary" component="p">
                {props.description2}
              </Typography>
            </div>
          </CardContent>
        </Collapse>
      </div>
    </div>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCategories);
