import React from "react";
import Firebase from "firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import BusinessIcon from "@material-ui/icons/Business";

// core components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Form
} from "reactstrap";

export default function SectionPills(props) {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [pills, setPills] = React.useState("1");
  const [dialog, setdialog] = React.useState(false);
  const [email, setemail] = React.useState(Firebase.auth().currentUser.email);
  const [message, setmessage] = React.useState("");
  const [subject, setsubject] = React.useState("");

  const handleSubmit = async event => {
    event.preventDefault();
    const id = new Date().getTime().toString();
    const uid = props.card;
    await Firebase.database()
      .ref("/contactcard")
      .push({ id, message, email, subject, uid });
    setdialog(true);
    setemail(Firebase.auth().currentUser.email);
    setmessage("");
    setsubject("");
  };

  const closedialog = () => {
    setdialog(false);
  };

  return (
    <div>
      <br />
      <br /> <br /> <br />
      <div className="nav-align-center">
        <Nav
          className="nav-pills-info nav-pills-just-icons"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              className={pills === "1" ? "active" : ""}
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                setPills("1");
              }}
            >
              {/* <i className="now-ui-icons business_badge" /> */}
              <i>
                <PermIdentityIcon fontSize="large" />
              </i>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={pills === "2" ? "active" : ""}
              href="#pablo"
              onClick={props.handleOpen}
            >
              {/* <i className="now-ui-icons business_briefcase-24" /> */}
              <i>
                <BusinessIcon fontSize="large" />
              </i>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={pills === "3" ? "active" : ""}
              href="#pablo"
              onClick={props.handleOpen}
            >
              <i>
                <LocationOnOutlinedIcon fontSize="large" />
              </i>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent className="gallery" activeTab={"pills" + pills}>
        <TabPane tabId="pills1">
          <Container>
            <div className="text-center">
              <br />

              <h2 className="title">Want to work with us?</h2>
              <p className="description">
                Your project is very important to us.
              </p>
              <br />
              <Row>
                <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                  <Form onSubmit={handleSubmit}>
                    <InputGroup
                      className={
                        "input-lg" + (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        required
                        placeholder="Email..."
                        value={email}
                        type="email"
                        name="newEmail"
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                        onChange={e => setemail(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "input-lg" + (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        required
                        placeholder="Subject ..."
                        type="text"
                        name="newSubject"
                        value={subject}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        onChange={e => setsubject(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <div className="textarea-container">
                      <Input
                        required
                        cols="80"
                        name="newMessage"
                        placeholder="Type a message..."
                        rows="4"
                        value={message}
                        type="textarea"
                        onChange={e => setmessage(e.target.value)}
                      ></Input>
                    </div>
                    <div className="send-button">
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        size="lg"
                      >
                        Send Message
                      </Button>
                      <br />
                      <br />
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </Container>
        </TabPane>
        <TabPane tabId="pills3"></TabPane>
      </TabContent>
      <Dialog
        open={dialog}
        onClose={closedialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Thank You"}
            {", Your message has been received."}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={closedialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
