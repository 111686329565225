/* eslint-disable no-unused-vars */
import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import Footer from "components/Footers/Footer.js";

// sections for this page

import SignUp from "views/index-sections/SignUp.js";
import ContactUs from "views/index-sections/ContactUs.js";
import ProductCategories from "views/index-sections/ProductCategories.js";
import Description from "views/index-sections/Description.js";
import Explain from "views/index-sections/Explain.js";
import VectorMap from "views/index-sections/Location";

/* eslint-enable no-unused-vars */

function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          <Explain />
          <Description />
          <ProductCategories />
          <SignUp />
          <ContactUs />
          <VectorMap />
        </div>
      </div>

      <Footer class={"footer footer-default"} />
    </>
  );
}

export default Index;
