/*

===========================================================================
* Kachila - v1.0.1

- Changed Google Auth: kachilagroup.firebaseapp.com to kachilagroup.com
- Added Firebase SDK to public/index.html
- Remove words 'Register, Advertise, Shop IPs' 
===========================================================================
* Kachila - v1.0.0

- First Released Verison
===========================================================================

*/

import React from "react";
import ReactDOM from "react-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/nucleo-icons-page-styles.css";

import App from "./App";
// import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));
