import React from "react";
// import { Link } from "react-router-dom";
import app from "views/auth/base.js";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

// import Button from "@material-ui/core/Button";

// reactstrap components
import {
  Button as ButtonNavBar,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  // NavLink,
  Nav,
  // Modal,
  // ModalBody,
  Container
} from "reactstrap";

function DefaultNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("bg-primary");
  // const [navbarColor] = React.useState("");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("bg-primary");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("bg-primary");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              K A C H I L A
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar" />
              <span className="navbar-toggler-bar middle-bar" />
              <span className="navbar-toggler-bar bottom-bar" />
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <ButtonNavBar
                  className="nav-link btn-neutral"
                  color="info"
                  onClick={() => app.auth().signOut()}
                >
                  <i className="now-ui-icons arrows-1_share-66 mr-1" />
                  <p>Sign Out</p>
                </ButtonNavBar>
              </NavItem>

              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#pablo"
                  nav
                  onClick={e => e.preventDefault()}
                >
                  <i className="now-ui-icons users_circle-08 mr-1" />
                  <p>Account</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => props.handle(true)}>
                    {/* <i className="now-ui-icons business_chart-pie-36 mr-1" /> */}
                    Settings
                  </DropdownItem>
                  <DropdownItem onClick={() => props.feedback(true)}>
                    {/* <i className="now-ui-icons design-app mr-1" /> */}
                    Feedback
                  </DropdownItem>{" "}
                </DropdownMenu>
              </UncontrolledDropdown>

              {/* <NavItem>
                <NavLink href="/checkout" id="">
                  <i className="now-ui-icons shopping_cart-simple mr-1" />
                  <p className="d-lg-none d-xl-none">Cart</p>
                </NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default withRouter(DefaultNavbar);
