import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import app from "views/auth/base.js";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Typography from "@material-ui/core/Typography";
// import Paper from "@material-ui/core/Paper";
// import { withRouter } from "react-router";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Firebase from "firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Avatar from "@material-ui/core/Avatar";

import Status from "views/settings/status";

import {
  NavItem,
  NavLink,
  Nav,
  // Card,
  // CardHeader,
  // CardBody,
  TabContent,
  TabPane,
  Form,
  Modal,
  ModalBody
} from "reactstrap";
import { Container } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0)
  }
}));

export default function UserDetails(props) {
  const classes = useStyles();
  const [firstR, setfirstR] = React.useState("");
  const [lastR, setlastR] = React.useState("");
  const [addR, setaddR] = React.useState("");
  const [cityR, setcityR] = React.useState("");
  const [zipR, setzipR] = React.useState("");
  const [countryR, setcountryR] = React.useState("");
  const [phoneR, setphoneR] = React.useState("");
  const [privacyR, setprivacyR] = React.useState(false);
  const [orgR, setorgR] = React.useState("");
  const [webR, setwebR] = React.useState("");
  const [settings, setsettings] = React.useState({});
  const [fetch, setfetch] = React.useState("");
  const [iconTabs, setIconTabs] = React.useState("2");
  const [modal2, setModal2] = React.useState(false);
  const mount = React.useRef(settings);

  React.useEffect(() => {
    if (mount.current) {
      setfetch("default");
      let ref = Firebase.database().ref(
        "/usersettings/" + Firebase.auth().currentUser.uid
      );
      const settings = snapshot => {
        if (snapshot.val()) setsettings(snapshot.val());
      };
      ref.on("value", settings);
      return () => {
        ref.off("value", settings);
      };
    }
  }, []);

  React.useEffect(() => {
    if (fetch === "default") {
      if (settings.privacy === true) {
        props.verify(settings.privacy);
        setaddR(settings.add);
        setcityR(settings.city);
        setcountryR(settings.country);
        // setemailR(settings.email);
        setfirstR(settings.first);
        setlastR(settings.last);
        setorgR(settings.org);
        setphoneR(settings.phone);
        setprivacyR(settings.privacy);
        setwebR(settings.web);
        setzipR(settings.zip);
      }
    }
  }, [fetch, settings, props]);

  const handleSubmit = async event => {
    event.preventDefault();
    const random = {
      add: addR,
      city: cityR,
      country: countryR,
      email: Firebase.auth().currentUser.email,
      first: firstR,
      last: lastR,
      org: orgR,
      phone: phoneR,
      privacy: privacyR,
      web: webR,
      zip: zipR
    };
    await Firebase.database()
      .ref("/usersettings/" + Firebase.auth().currentUser.uid)
      .set(random);
    props.handle(false);
  };

  const handleChangeP = async event => {
    event.preventDefault();
    const { currentPassword, newPassword } = event.target.elements;

    try {
      await app
        .auth()
        .signInWithEmailAndPassword(
          app.auth().currentUser.email,
          currentPassword.value
        );
      await app.auth().currentUser.updatePassword(newPassword.value);
      alert("Password Updated");
    } catch (error) {
      alert(error);
    }
  };

  const handleDelete = () => {
    setModal2(true);
    props.handle(false);
  };

  return (
    <>
      <React.Fragment>
        <CssBaseline />
        <Dialog open={props.dialog} aria-labelledby="form-dialog-title">
          {/* <Button onClick={fetch}>Fetch</Button> */}
          <div>
            <div className="space-20" />
            <DialogTitle
              style={{
                display: "flex",
                justifyContent: "center"
              }}
              id="alert-dialog-title"
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 5,
                  marginBottom: 10
                }}
                id="alert-dialog-title"
              >
                <Avatar style={{ backgroundColor: "#e91e63" }}>
                  <LockOutlinedIcon />
                </Avatar>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 15
                }}
              >
                {" "}
                User Settings{" "}
              </div>
              <Nav className="justify-content-center" role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={iconTabs === "1" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setIconTabs("1");
                    }}
                  >
                    {/* <i className="now-ui-icons objects_umbrella-13"></i> */}
                    Status
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={iconTabs === "2" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setIconTabs("2");
                    }}
                  >
                    {/* <i className="now-ui-icons shopping_cart-simple"></i> */}
                    Profile
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={iconTabs === "3" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setIconTabs("3");
                    }}
                  >
                    {/* <i className="now-ui-icons shopping_shop"></i> */}
                    Account
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={"iconTabs" + iconTabs}>
                <TabPane tabId="iconTabs1">
                  <Status />
                  <div
                    className={classes.button}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Button
                      onClick={() => {
                        props.handle(false);
                        setIconTabs("2");
                      }}
                      type="submit"
                      color="primary"
                    >
                      Back
                    </Button>
                  </div>
                </TabPane>
                <TabPane tabId="iconTabs2">
                  <br />
                  <DialogContent>
                    <Form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            required
                            id="email"
                            name="email"
                            label="Email"
                            fullWidth
                            autoComplete="email"
                            value={Firebase.auth().currentUser.email}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="firstName"
                            name="firstName"
                            label="First name"
                            fullWidth
                            autoComplete="fname"
                            value={firstR}
                            onChange={e => setfirstR(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="lastName"
                            name="lastName"
                            label="Last name"
                            fullWidth
                            autoComplete="lname"
                            value={lastR}
                            onChange={e => setlastR(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            id="address"
                            name="address"
                            label="Address"
                            fullWidth
                            autoComplete="billing address-line1"
                            value={addR}
                            onChange={e => setaddR(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="city"
                            name="city"
                            label="City"
                            fullWidth
                            autoComplete="billing address-level2"
                            value={cityR}
                            onChange={e => setcityR(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="zip"
                            name="zip"
                            label="Zip / Postal code"
                            fullWidth
                            autoComplete="billing postal-code"
                            value={zipR}
                            onChange={e => setzipR(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="country"
                            name="country"
                            label="Country"
                            fullWidth
                            autoComplete="billing country"
                            value={countryR}
                            onChange={e => setcountryR(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="phone"
                            name="phone"
                            label="Phone"
                            fullWidth
                            autoComplete="phone"
                            value={phoneR}
                            onChange={e => setphoneR(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="business"
                            name="business"
                            label="Organisation"
                            fullWidth
                            value={orgR}
                            onChange={e => setorgR(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="linkedin"
                            name="linkedin"
                            label="LikedIn or Weblink"
                            fullWidth
                            value={webR}
                            onChange={e => setwebR(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                required
                                checked={privacyR}
                                color="secondary"
                                name="privacy"
                                value={privacyR}
                                onChange={e => setprivacyR(e.target.checked)}
                              />
                            }
                            label="Agree to Privacy Policy"
                          />
                        </Grid>
                      </Grid>
                      <div
                        className={classes.button}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end"
                        }}
                      >
                        <div
                        // style={{
                        //   paddingRight: "40px"
                        // }}
                        >
                          <Button
                            onClick={() => props.handle(false)}
                            color="primary"
                          >
                            Back
                          </Button>
                        </div>

                        <Button type="submit" color="primary">
                          Update
                        </Button>
                      </div>
                    </Form>
                  </DialogContent>
                </TabPane>
                <TabPane tabId="iconTabs3">
                  <div className="space-40" />
                  <Container>
                    <h6 style={{ fontSize: 14 }}>Reset Password</h6>
                    <div className="space-20" />
                    <Form onSubmit={handleChangeP}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            name="currentPassword"
                            type="password"
                            label="Current Password"
                            fullWidth
                            // autoComplete="password"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            label="New Password"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div
                            className={classes.button}
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Button type="submit" color="primary">
                              Update
                            </Button>
                          </div>
                        </Grid>

                        <div className="space-40" />
                        <Grid item xs={12}>
                          <h6 style={{ fontSize: 14 }}>Delete Account</h6>
                        </Grid>

                        <Grid item xs={12}>
                          <div
                            className={classes.button}
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <Button onClick={handleDelete} color="secondary">
                              Delete
                            </Button>
                          </div>
                        </Grid>

                        <div className="space-20" />
                        <br />
                        <Grid item xs={12}>
                          <div
                            className={classes.button}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end"
                            }}
                          >
                            <Button
                              onClick={() => {
                                props.handle(false);
                                setIconTabs("2");
                              }}
                              type="submit"
                              color="primary"
                            >
                              Back
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Form>
                  </Container>
                </TabPane>
              </TabContent>
            </DialogTitle>
          </div>
        </Dialog>
        <Modal modalClassName="modal-mini modal-info" isOpen={modal2}>
          <div className="modal-header justify-content-center">
            {/* <Modal.Header closeButton></Modal.Header> */}
            <div className="modal-profile">
              <i className="now-ui-icons users_circle-08"></i>
            </div>
          </div>
          <ModalBody>
            <p>
              Are you sure you want to permanently delete your account and all
              your information.
            </p>
          </ModalBody>
          <div className="modal-footer justify-content-center">
            <Button
              className="btn-neutral"
              color="primary"
              type="button"
              onClick={() => {
                app.auth().currentUser.delete();
                setModal2(false);
              }}
            >
              Confirm
            </Button>
            <Button
              className="btn-neutral"
              color="primary"
              type="button"
              onClick={() => setModal2(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    </>
  );
}
