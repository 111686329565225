import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import Container from "@material-ui/core/Container";
// import Typography from "./Typography";
// import CardContent from "@material-ui/core/CardContent";
// import Collapse from "@material-ui/core/Collapse";
// import Recipe from "./Categories/Recipe";
import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";

// import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";

// import CardMedia from "@material-ui/core/CardMedia";

const styles = theme => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4)
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap"
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100
    },
    "&:hover": {
      zIndex: 1
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15
    },
    "&:hover $imageMarked": {
      opacity: 0
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor"
    }
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%"
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity")
  }
});

function ProductCategories(props) {
  const { classes } = props;

  const description = [
    {
      id: "1",
      icon: "fas fa-microchip fa-4x",
      title: "Intellectual Property Management",
      info:
        "Facilitation and brokering of exclusive IPs between the Military and Commercial sector",
      color: "#ff00d6"
    },
    {
      id: "2",
      icon: "fas fa-search-dollar fa-4x",
      title: "Optimisation and Analytics",
      info:
        "Machine Learning driven indexing to classify IPs, identify redundent IPs in the industry and perform complex market analysis.",
      color: "#7bdcb5"
    },
    {
      id: "3",
      icon: "fas fa-globe-africa fa-4x",
      title: "Stakeholder Management",
      info:
        "Managing development projects under a shared cost between multiple partners and a common balance sheet.",
      color: "#4fc3f7"
    },
    {
      id: "4",
      icon: "fas fa-lightbulb fa-4x",
      title: "Business Development",
      info:
        "Generate new markets, identifying decision makers and influencers to change behaviour and bring favourable outcome to technology development projects",
      color: "#f96332"
    }
  ];

  return (
    <div
      // className="section section-team text-center"
      style={{
        backgroundImage:
          "url(" + require("assets/img/productCurvyLines.png") + ")",
        backgroundSize: "100"
      }}
    >
      <div className={classes.cardGrid} maxwidth="md">
        <div className="space-40" />
        <div className="space-40" />
        <div className="space-40" />
        {/* End hero unit */}

        <Grid container spacing={0}>
          {description.map(card => (
            <Grid item key={card.id} xs={12} sm={6} md={3}>
              <Container>
                <div className="team-player" style={{ textAlign: "center" }}>
                  <i
                    className={card.icon}
                    // style={{ color: "#fe9200" }}
                    style={{ color: card.color }}
                  />
                  <h4 style={{ fontWeight: "bold" }}>{card.title}</h4>

                  <p className="category text-info" />
                  <p className="description" style={{ fontWeight: "bold" }}>
                    {card.info}
                  </p>
                </div>
              </Container>
            </Grid>
          ))}
        </Grid>

        <div className="space-40" />
      </div>
    </div>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCategories);
