import React, { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
// import classnames from "classnames";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import "assets/style.css";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import TimelineIcon from "@material-ui/icons/Timeline";
import SettingsIcon from "@material-ui/icons/Settings";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import {
  //   Row,
  //   Col,
  //   Button,
  //   CardHeader,
  //   Card,
  //   CardBody,
  Progress
  //   TabContent,
  //   TabPane
} from "reactstrap";

// import PageTitle from '../../../Layout/AppMain/PageTitle';

import {
  AreaChart,
  Area,
  Line,
  ResponsiveContainer,
  //   Bar,
  //   BarChart,
  //   ComposedChart,
  CartesianGrid,
  Tooltip,
  LineChart
  //   XAxis,
  //   YAxis,
  //   Legend
} from "recharts";
import { Container } from "@material-ui/core";

// import {
//     faAngleUp,
//     faArrowRight,
//     faArrowUp,
//     faArrowLeft,
//     faAngleDown
// } from '@fortawesome/free-solid-svg-icons';

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

// import avatar1 from '../../../assets/utils/images/avatars/1.jpg';
// import avatar2 from '../../../assets/utils/images/avatars/2.jpg';
// import avatar3 from '../../../assets/utils/images/avatars/3.jpg';
// import avatar4 from '../../../assets/utils/images/avatars/4.jpg';

const data = [
  { name: "Page A", Data1: 400, pv: 240, amt: 2400 },
  { name: "Page B", Data1: 300, pv: 198, amt: 210 },
  { name: "Page C", Data1: 200, pv: 900, amt: 290 },
  { name: "Page D", Data1: 278, pv: 398, amt: 200 },
  { name: "Page E", Data1: 189, pv: 400, amt: 211 },
  { name: "Page F", Data1: 239, pv: 380, amt: 250 },
  { name: "Page G", Data1: 349, pv: 430, amt: 200 },
  { name: "Page C", Data1: 200, pv: 600, amt: 220 },
  { name: "Page D", Data1: 478, pv: 708, amt: 200 },
  { name: "Page E", Data1: 290, pv: 900, amt: 281 },
  { name: "Page F", Data1: 190, pv: 300, amt: 150 },
  { name: "Page G", Data1: 390, pv: 400, amt: 210 }
];

const data2 = [
  { name: "Page A", Data1: 540, Data2: 524, amt: 124 },
  { name: "Page B", Data1: 730, Data2: 413, amt: 322 },
  { name: "Page C", Data1: 820, Data2: 798, amt: 522 },
  { name: "Page D", Data1: 627, Data2: 439, amt: 320 },
  { name: "Page E", Data1: 318, Data2: 748, amt: 621 },
  { name: "Page D", Data1: 947, Data2: 679, amt: 220 },
  { name: "Page E", Data1: 128, Data2: 198, amt: 721 },
  { name: "Page F", Data1: 313, Data2: 238, amt: 515 },
  { name: "Page G", Data1: 534, Data2: 343, amt: 321 }
];

export default function Analytics(props) {
  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnterTimeout={0}
        transitionEnter={true}
        transitionLeave={false}
      >
        <div>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} md={4}>
              <div className="product_stat">
                <div
                  className="cardproduct mb-3 widget-chart"
                  onClick={props.handleOpen}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 5,
                      marginBottom: 10
                    }}
                    id="alert-dialog-title"
                  >
                    <Avatar
                      style={{
                        backgroundColor: "#e91e63",
                        width: 60,
                        height: 60
                      }}
                    >
                      <TimelineIcon style={{ fontSize: 35 }} />
                    </Avatar>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 15
                    }}
                  >
                    {" "}
                    Analytics{" "}
                  </div>

                  <div className="widget-description">
                    <span className="text-danger pl-1 pr-1">
                      <LockOutlinedIcon />
                      <span className="pl-1"></span>
                    </span>
                  </div>

                  <div className="widget-chart-wrapper chart-wrapper-relative">
                    <ResponsiveContainer height={187}>
                      <AreaChart
                        data={data}
                        margin={{ top: -45, right: 0, left: 0, bottom: 0 }}
                      >
                        <defs>
                          <linearGradient
                            id="colorPv2"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="10%"
                              stopColor="var(--warning)"
                              stopOpacity={0.7}
                            />
                            <stop
                              offset="90%"
                              stopColor="var(--warning)"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Area
                          type="monotoneX"
                          dataKey="Data1"
                          stroke="var(--warning)"
                          strokeWidth={2}
                          fillOpacity={1}
                          fill="url(#colorPv2)"
                        />
                        <Tooltip />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <div className="product_stat">
                <div
                  className="cardproduct mb-3 widget-chart"
                  onClick={props.handleOpen}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 5,
                      marginBottom: 10
                    }}
                    id="alert-dialog-title"
                  >
                    <Avatar
                      style={{
                        backgroundColor: "#4d4d4d",

                        width: 60,
                        height: 60
                      }}
                    >
                      <TouchAppIcon style={{ fontSize: 30 }} />
                    </Avatar>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 15
                    }}
                  >
                    {" "}
                    User Interactions{" "}
                  </div>

                  <div className="widget-description">
                    <span className="text-danger pl-1 pr-1">
                      <LockOutlinedIcon />
                      <span className="pl-1"></span>
                    </span>
                  </div>

                  <div className="widget-chart-wrapper chart-wrapper-relative">
                    <ResponsiveContainer height={187}>
                      <LineChart
                        width={500}
                        height={300}
                        data={data2}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 0
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        {/* <XAxis dataKey="name" />
                        <YAxis /> */}
                        <Tooltip />
                        {/* <Legend /> */}
                        <Line
                          type="monotone"
                          dataKey="Data1"
                          stroke="#8884d8"
                          activeDot={{ r: 8 }}
                        />
                        <Line
                          type="monotone"
                          dataKey="Data2"
                          stroke="#82ca9d"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <div className="product_stat" onClick={props.handleOpen}>
                <div className="cardproduct">
                  <div className="cardproduct mb-3 bg-love-kiss widget-chart card-border">
                    <div className="widget-chart-content text-white">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 5,
                          marginBottom: 10
                        }}
                        id="alert-dialog-title"
                      >
                        <Avatar
                          style={{
                            backgroundColor: "#f0f1f1",
                            width: 60,
                            height: 60
                          }}
                        >
                          <SettingsIcon
                            style={{
                              color: "#e91e63",
                              fontSize: 30
                            }}
                          />
                        </Avatar>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: 30
                        }}
                      >
                        Status{" "}
                      </div>

                      <div className="widget-description">
                        <span className="text-white pl-1 pr-1">
                          <LockOutlinedIcon />
                          <span className="pl-1"></span>
                        </span>
                      </div>
                    </div>
                    <div className="widget-chart-wrapper">
                      <ResponsiveContainer width="100%" aspect={3.0 / 1.0}>
                        <LineChart
                          data={data}
                          margin={{ top: 0, right: 5, left: 5, bottom: 0 }}
                        >
                          <Line
                            type="monotone"
                            dataKey="pv"
                            stroke="#ffffff"
                            strokeWidth={3}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <Container>
                    <ResponsiveContainer height={203}>
                      <div className="widget-chart-wrapper chart-wrapper-relative">
                        <div className="widget-content">
                          <div className="widget-content-outer">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left mr-3">
                                <div className="widget-numbers fsize-3 text-muted"></div>
                              </div>

                              <div className="widget-content-right">
                                <div className="text-muted opacity-6">
                                  Progress
                                </div>
                              </div>
                            </div>
                            <br />
                            <div className="widget-progress-wrapper ">
                              <Progress
                                className="progress-bar-sm progress-bar-animated-alt"
                                color="success"
                                value="44"
                              />
                            </div>
                          </div>
                        </div>

                        <br />
                        <br />

                        <div className="widget-content">
                          <div className="widget-content-outer">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left mr-3">
                                <div className="widget-numbers fsize-3 text-muted"></div>
                              </div>
                              <div className="widget-content-right">
                                <div className="text-muted opacity-6">
                                  Generated Leads
                                </div>
                              </div>
                            </div>
                            <br />
                            <div className="widget-progress-wrapper mt-1">
                              <Progress
                                className="progress-bar-sm progress-bar-animated-alt"
                                color="warning"
                                value="60"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ResponsiveContainer>
                  </Container>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}
