import React from "react";
import "./Recipe.scss";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { blueGrey } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import { withStyles } from "@material-ui/core/styles";
// import Rating from "@material-ui/lab/Rating";
import Avatar from "@material-ui/core/Avatar";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import BookmarkIcon from "@material-ui/icons/Bookmark";
// import { Container } from "@material-ui/core";
// import ProductDialog from "views/database/productdialog";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FeedbackIcon from "@material-ui/icons/Feedback";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Report from "views/settings/report";


const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    minHeight: 500
  },
  media: {
    // height: 150,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: blueGrey[300]
  }
}));

export default function RecipeReviewCard(item) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [report, setreport] = React.useState(false);

  // const [open, setOpen] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function favClick() {
    item.favorite(item.value.uid);
  }

  function favCheck(some) {
    const index = item.showfav.filter(list => {
      return list.uid === some;
    });
    if (index > -1) {
      return true;
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card className={item.cardtype}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {item.value.name.charAt(0).toUpperCase()}
            </Avatar>
          }
          action={
            <div>
              <IconButton
                aria-label="settings"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon style={{ color: "#ff9400" }} />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setreport(true);
                  }}
                >
                  Report
                </MenuItem>
              </Menu>
            </div>
          }
          title={item.value.name}
          // subheader={item.value.role}
        />
        <Report open={report} set={setreport} item={item.value.uid} />
        <div onClick={() => item.openprod(item.value)}>
          <CardMedia className={classes.media} image={item.value.pic}>
            {item.value.status !== "verified" ? (
              <>
                <div className="image_overlay" />
                <div className="view_details">
                  {item.value.status === "deleted" ? (
                    <>
                      {"Pending "}
                      <DeleteIcon size="small"></DeleteIcon>
                    </>
                  ) : (
                    ""
                  )}
                  {item.value.status === "edited" ? (
                    <>
                      {" Pending "}
                      <EditIcon size="small"></EditIcon>
                    </>
                  ) : (
                    ""
                  )}
                  {item.value.status === "created" ? (
                    <>
                      {" Pending "}
                      <PublishIcon size="small"></PublishIcon>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}
          </CardMedia>
        </div>
        <div>
          <CardContent>
            <p>{item.value.role}</p>
            <Typography
              style={{ minHeight: "40px", maxHeight: "40px" }}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              {item.value.title}
            </Typography>
          </CardContent>
        </div>

        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites" onClick={favClick}>
            {favCheck(item.value.uid) ? (
              <FavoriteBorderIcon style={{ fontSize: "27" }} />
            ) : (
              <FavoriteIcon color="secondary" style={{ fontSize: "27" }} />
            )}
          </IconButton>

          {/* <StyledRating
            name="customized-color"
            value={2.5}
            max={3}
            precision={0.5}
            readOnly
            icon={<MonetizationOnOutlinedIcon fontSize="inherit" />}
          /> */}
          {item.value.type === "IPS" ? (
            <FolderSpecialIcon color="primary" style={{ fontSize: "29" }} />
          ) : (
            <FeedbackIcon color="primary" style={{ fontSize: "29" }} />

            // <AssignmentLateIcon style={{ fontSize: "27" }} />
          )}
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon style={{ fontSize: "27" }} />
          </IconButton>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Abstract:</Typography>
            <div style={{ textAlign: "start" }}>
              <Typography variant="body2" color="textSecondary" component="p">
                {item.value.abstract}
              </Typography>
            </div>
          </CardContent>
        </Collapse>

        {/* <ProductDialog open={open} handleClose={handleClose} /> */}

        {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <br />

          <div
            style={{ display: "flex", justifyContent: "center" }}
            id="alert-dialog-title"
          >
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
          </div>
          <DialogTitle
            style={{ display: "flex", justifyContent: "center" }}
            id="alert-dialog-title"
          >
            View Details
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>Detailed information is currently unavailable to you.</p>
              <p>
                Kachila is currently working with leading defence and consumer
                technology development companies and have to scale in size
                smartly and control the flow of information.
              </p>
              <p>
                To be able to see detailed information about various IPs,
                company profiles and contact details. Please click the 'request'
                button and we will get back to you.
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              Request Access
            </Button>
          </DialogActions>
        </Dialog> */}
      </Card>
    </>
  );
}
