import React, { useState, useEffect, useRef } from "react";

import Firebase from "firebase";
import * as firebase from "firebase/app";
import "firebase/storage";

import Button from "@material-ui/core/Button";

import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";
// import Container from "@material-ui/core/Container";
import app from "views/auth/base.js";

import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import IconButton from "@material-ui/core/IconButton";
import DialButton from "views/functions/DialButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import MenuItem from "@material-ui/core/MenuItem";
import ProductDialog from "views/database/productdialog.js";
import PublicIcon from "@material-ui/icons/Public";
import Recipe from "./Card/Recipe";
import Search from "./Search";
import AlertDialog from "./verifydialog";
import Upload from "views/database/upload";
import Picview from "views/database/picview";
import SnackBar from "views/database/snackbar";
// import PublishIcon from "@material-ui/icons/Publish";

import {
  NavItem,
  NavLink,
  Nav,
  Form
  // Card,
  // CardHeader,
  // CardBody,
  // TabContent,
  // TabPane,
  // Form,
  // Modal,
  // ModalBody
} from "reactstrap";
import { Container } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  }
}));

function Data(props) {
  const [nameR, setnameR] = React.useState("");
  const [roleR, setroleR] = React.useState("");
  const [uidR, setuidR] = React.useState("");
  const [picR, setpicR] = React.useState("");
  const [abstractR, setabstract] = React.useState("");
  const [infoR, setinfo] = React.useState("");
  const [titleR, settitle] = React.useState("");
  const [statusR, setstatus] = useState("");
  const [typeR, settypeR] = React.useState("IPS");
  const [iconTabs, setIconTabs] = React.useState("1");

  const [state, setState] = useState({ developers: [] });
  const [searchTerm, setSearchTerm] = React.useState("");
  const [GridResults, setGridResults] = React.useState([]);
  const [FilterResults, setFilterResults] = React.useState([]);
  const [dialog, setdialog] = useState(false);
  const [favourite, setfavourite] = useState({ users: [] });
  const [favitem, setfavitem] = useState(null);
  const [product, setproduct] = useState({});
  const [productdialog, setproductdialog] = React.useState(false);
  const [favlist, setfavlist] = useState("");
  const mount = useRef(state);
  const [filterstatus, setfilterstatus] = React.useState("");
  const favmount = useRef(favourite);
  const favupdate = useRef(favitem);
  const [topfilter, settopfilter] = React.useState("");
  const [uploadpic, setuploadpic] = React.useState(false);
  const [picid, setpicid] = React.useState("");
  const [snack, editsnack] = React.useState(false);
  const [oldpicid, setoldpicid] = React.useState("");
  const [picchange, setpicchange] = React.useState(false);

  const storage = firebase.storage().ref();

  const typeIP = [
    {
      value: "IPS",
      label: "Upload IP or Service"
    },
    {
      value: "REQ",
      label: "Request IP or Service"
    }
  ];

  const resetfilter = () => {
    setfilterstatus("default");
  };

  const handleIPClose = () => {
    setdialog(false);
    setnameR("");
    setroleR("");
    setpicR("");
    settitle("");
    setabstract("");
    setinfo("");
    deletepiconcancel();
  };

  React.useEffect(() => {
    if (mount.current) {
      // getSettingsData();
      setfilterstatus("default");
      let ref = app.database().ref("/database");
      const state = snapshot => {
        if (snapshot.val()) setState(snapshot.val());
      };
      ref.on("value", state);
      return () => {
        ref.off("value", state);
      };
    }
  }, []);

  React.useEffect(() => {
    let ref = app.database().ref("/favourites");
    const favourite = snapshot => {
      if (snapshot.val()) setfavourite(snapshot.val());
    };
    ref.on("value", favourite);
    return () => {
      ref.off("value", favourite);
    };
  }, []);

  // useEffect(() => {
  //   getUserData();
  //   console.log("Get Developers");
  //   setfilterstatus("default");
  //   getSettingsData();
  //   console.log("Get FavData");
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (mount.current === state) {
    } else {
      writeUserData();
      console.log("Write Developers");
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (favmount.current === favourite) {
    } else {
      writeFavData();
      console.log("Write Favourites");
    }
    // eslint-disable-next-line
  }, [favourite]);

  useEffect(() => {
    if (favupdate.current === favitem) {
    } else {
      AddFavorite();
    }
    // eslint-disable-next-line
  }, [favitem]);

  const handleChange = e => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  useEffect(() => {
    if (searchTerm !== "") {
      const results = FilterResults.filter(
        list =>
          list.title.toLowerCase().includes(searchTerm) ||
          list.name.toLowerCase().includes(searchTerm) ||
          list.info.toLowerCase().includes(searchTerm) ||
          list.abstract.toLowerCase().includes(searchTerm) ||
          list.role.toLowerCase().includes(searchTerm)
      );
      setGridResults(results.reverse());
    } else {
      setGridResults(FilterResults.reverse());
    }
  }, [state, searchTerm, FilterResults]);

  const removeSearch = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    const { users } = favourite;
    const listfavs = users.filter(list => {
      return list.auth === Firebase.auth().currentUser.uid;
    });
    const { developers } = state;
    const results = developers.filter(list =>
      listfavs.some(user => user.favitem === list.uid)
    );
    setfavlist(results);
  }, [favourite, state]);

  // Various Filter States & Changes
  useEffect(() => {
    const { developers } = state;
    if (filterstatus === "edit") {
      if (Firebase.auth().currentUser.uid !== "YktJ5sTUD0MI0qZ8lF7iiwyW8fC3") {
        const results = developers.filter(list => {
          return (
            list.authid === Firebase.auth().currentUser.uid &&
            list.status !== "deleted"
          );
        });
        setFilterResults(results);
      } else {
        const results = developers.filter(list => {
          return list.status === "verified";
        });

        setFilterResults(results);
      }
    }
    if (filterstatus === "verify") {
      const results = developers.filter(list => {
        return list.status !== "verified";
      });
      setFilterResults(results);
    }
    if (filterstatus === "favs") {
      const { users } = favourite;
      const listfavs = users.filter(list => {
        return list.auth === Firebase.auth().currentUser.uid;
      });
      const { developers } = state;
      const results = developers.filter(list =>
        listfavs.some(
          user => user.favitem === list.uid && list.status !== "deleted"
        )
      );
      setFilterResults(results);
    }
    if (filterstatus === "default") {
      const results = developers.filter(list =>
        list.status.includes("verified")
      );

      setFilterResults(results);
    }
    if (topfilter !== "") {
      const results = developers.filter(
        list =>
          list.status.includes("verified") && list.type.includes(topfilter)
      );
      setFilterResults(results);
    }
  }, [state, filterstatus, favourite, topfilter]);

  const writeUserData = async () => {
    await app
      .database()
      .ref("/database")
      .set(state);
    console.log("DATA SAVED");
  };

  const writeFavData = async () => {
    await app
      .database()
      .ref("/favourites")
      .set(favourite);
    console.log("FAV SAVED");
  };

  // const getSettingsData = () => {
  //   let ref = app.database().ref("/favourites");
  //   const favourite = snapshot => {
  //     if (snapshot.val()) setfavourite(snapshot.val());
  //   };
  //   ref.on("value", favourite);
  //   return () => {
  //     ref.off("value", favourite);
  //   };
  // };

  // const getUserData = () => {
  //   let ref = app.database().ref("/database");
  //   const state = snapshot => {
  //     if (snapshot.val()) setState(snapshot.val());
  //   };
  //   ref.on("value", state);
  //   return () => {
  //     ref.off("value", state);
  //   };
  // };

  const AddFavorite = () => {
    let auth = Firebase.auth().currentUser.uid;
    const { users } = favourite;
    const favIndex = users.findIndex(data => {
      return data.favitem === favitem && data.auth === auth;
    });
    if (favIndex > -1) {
      const { users } = favourite;
      const removefav = users.filter(data => {
        return !(data.favitem === favitem && data.auth === auth);
      });

      setfavourite({ users: removefav });
      setfavitem(null);
    } else {
      users.push({ auth, favitem });
      setfavourite({ users });
      setfavitem(null);
    }
  };

  const deleteoldpic = () => {
    console.log(oldpicid);
    console.log(picid);
    console.log(picchange);
    if (picchange) {
      storage
        .child(
          "images/" +
            Firebase.auth().currentUser.uid +
            "/" +
            uidR +
            "/" +
            oldpicid
        )
        .delete()
        .then(() => {
          console.log(oldpicid, " deleted from firebase.");
        })
        .catch(function(error) {
          "Uh-oh, an error occurred!";
          console.log(error);
        });
    }

    setpicchange(false);
    setuploadpic(false);
  };

  const deletepiconcancel = () => {
    console.log(oldpicid);
    console.log(picid);
    console.log(picchange);

    if (picchange) {
      storage
        .child(
          "images/" + Firebase.auth().currentUser.uid + "/" + uidR + "/" + picid
        )
        .delete()
        .then(() => {
          console.log(picid, " deleted from firebase.");
        })
        .catch(function(error) {
          "Uh-oh, an error occurred!";
          console.log(error);
        });
    }
    setpicchange(false);
    setuploadpic(false);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setdialog(false);
    deleteoldpic();

    let name = nameR;
    let role = roleR;
    let uid = uidR;
    let pic = picR;
    let abstract = abstractR;
    let title = titleR;
    let info = infoR;
    let status = statusR;
    let type = typeR;
    let pid = picid;

    if (uid) {
      const { developers } = state;
      const devIndex = developers.findIndex(data => {
        return data.uid === uid;
      });

      developers[devIndex].name = name;
      developers[devIndex].role = role;
      developers[devIndex].pic = pic;
      developers[devIndex].title = title;
      developers[devIndex].abstract = abstract;
      developers[devIndex].info = info;
      developers[devIndex].status = status;
      developers[devIndex].type = type;
      developers[devIndex].pid = pid;

      setState({ developers });
    } else {
      const { developers } = state;
      const uid = new Date().getTime().toString();
      const authid = Firebase.auth().currentUser.uid;
      const status = "created";
      if (firstsubmit(Firebase.auth().currentUser.uid)) {
        editsnack(true);
      } else {
      }
      developers.push({
        uid,
        authid,
        name,
        role,
        pic,
        title,
        abstract,
        info,
        status,
        type,
        pid
      });
      setState({ developers });
    }

    setuidR("");
    setnameR("");
    setroleR("");
    setpicR("");
    settitle("");
    setabstract("");
    setinfo("");
    settypeR("IPS");
  };

  const removeData = developer => {
    const { developers } = state;
    const newState = developers.filter(data => {
      return data.uid !== developer.uid;
    });
    setState({ developers: newState });
  };

  const deleteData = developer => {
    // setdialog(true);
    // setuidR(developer.uid);
    // setnameR(developer.name);
    // setroleR(developer.role);
    // setpicR(developer.pic);
    // settitle(developer.title);
    // setabstract(developer.abstract);
    // setinfo(developer.info);
    // settypeR(developer.type);
    // setpicid(developer.pid);
    // setstatus("deleted");

    const { developers } = state;
    const devIndex = developers.findIndex(data => {
      return data.uid === developer.uid;
    });

    developers[devIndex].name = developer.name;
    developers[devIndex].role = developer.role;
    developers[devIndex].pic = developer.pic;
    developers[devIndex].title = developer.title;
    developers[devIndex].abstract = developer.abstract;
    developers[devIndex].info = developer.info;
    developers[devIndex].status = "deleted";
    developers[devIndex].type = developer.type;
    developers[devIndex].pid = developer.pid;

    setState({ developers });
  };

  const updateData = developer => {
    setdialog(true);
    setuidR(developer.uid);
    setnameR(developer.name);
    setroleR(developer.role);
    setpicR(developer.pic);
    settitle(developer.title);
    setabstract(developer.abstract);
    setinfo(developer.info);
    settypeR(developer.type);
    setpicid(developer.pid);
    setstatus("edited");
  };

  const verifyData = developer => {
    setdialog(true);
    setuidR(developer.uid);
    setnameR(developer.name);
    setroleR(developer.role);
    setpicR(developer.pic);
    settitle(developer.title);
    setabstract(developer.abstract);
    setinfo(developer.info);
    settypeR(developer.type);
    setpicid(developer.pid);
    setstatus("verified");
  };

  const [verifydialog, setverifydialog] = React.useState(false);

  const handleproduct = developer => {
    if (props.verify) {
      setproduct(developer);
      setproductdialog(true);
    } else {
      setverifydialog(true);
    }
  };

  const addcard = () => {
    if (props.verify) {
      setdialog(true);
    } else {
      setverifydialog(true);
    }
  };

  const handleproductclose = () => {
    setproductdialog(false);
  };

  function firstsubmit(some) {
    const { developers } = state;
    const index = developers.filter(list => {
      return list.authid === some && list.status === "verified";
    });
    if (index.length < 1) {
      return true;
    }
  }

  const classes = useStyles();

  return (
    <>
      <div className="space-30" />
      <SnackBar snack={snack} edit={editsnack} />
      <AlertDialog
        open={verifydialog}
        handle={setverifydialog}
        addupdate={props.addupdate}
      />
      <ProductDialog
        dialog={productdialog}
        product={product}
        close={handleproductclose}
        premium={props.premium}
      />
      <div className={classes.cardGrid} maxwidth="md">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
        >
          <Grid>
            <div className="nav-align-center">
              <Nav className="nav-pills-custom" pills role="tablist" tabs>
                <NavItem>
                  <NavLink
                    className={iconTabs === "1" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setIconTabs("1");
                      settopfilter("");
                    }}
                  >
                    {/* <i className="now-ui-icons objects_umbrella-13"></i> */}
                    <PublicIcon />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={iconTabs === "2" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setIconTabs("2");
                      settopfilter("IPS");
                      resetfilter();
                    }}
                  >
                    {/* <i className="now-ui-icons shopping_cart-simple"></i> */}
                    {/* <PublishIcon /> */}Submits
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={iconTabs === "3" ? "active" : ""}
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      setIconTabs("3");
                      settopfilter("REQ");
                      resetfilter();
                    }}
                  >
                    {/* <i className="now-ui-icons shopping_shop"></i> */}
                    Requests
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Grid>
        </Grid>

        <Container>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={6} md={4}>
              {filterstatus !== "default" ? (
                <>
                  <IconButton aria-label="settings" onClick={resetfilter}>
                    <HighlightOffIcon
                      size="small"
                      color="primary"
                    ></HighlightOffIcon>
                  </IconButton>
                  <Button onClick={resetfilter}>Remove Filter</Button>
                </>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Search
                value={searchTerm}
                onSubmit={handleChange}
                removeSearch={removeSearch}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {GridResults.map(developer => (
              <Grid item key={developer.uid} xs={12} sm={6} md={3}>
                {filterstatus === "favs" && developer.status === "verified" ? (
                  <>
                    <Recipe
                      cardtype="product"
                      value={developer}
                      favorite={setfavitem}
                      item={favitem}
                      showfav={favlist}
                      openprod={handleproduct}
                      closeprod={handleproductclose}
                    ></Recipe>
                  </>
                ) : (
                  ""
                )}
                {filterstatus === "default" ? (
                  <>
                    <Recipe
                      cardtype="product"
                      value={developer}
                      favorite={setfavitem}
                      item={favitem}
                      showfav={favlist}
                      openprod={handleproduct}
                      closeprod={handleproductclose}
                    ></Recipe>
                  </>
                ) : (
                  ""
                )}
                {filterstatus === "favs" && developer.status !== "verified" ? (
                  <>
                    <Recipe
                      cardtype="product_pending"
                      value={developer}
                      favorite={setfavitem}
                      item={favitem}
                      showfav={favlist}
                      openprod={handleproduct}
                      closeprod={handleproductclose}
                    ></Recipe>
                  </>
                ) : (
                  ""
                )}
                {filterstatus === "edit" ? (
                  <>
                    <Recipe
                      cardtype="product_pending"
                      value={developer}
                      favorite={setfavitem}
                      item={favitem}
                      showfav={favlist}
                      openprod={handleproduct}
                      closeprod={handleproductclose}
                    ></Recipe>
                    <IconButton
                      aria-label="settings"
                      onClick={() => updateData(developer)}
                    >
                      <EditIcon size="small" color="primary">
                        Edit
                      </EditIcon>
                    </IconButton>
                    <IconButton
                      aria-label="settings"
                      onClick={() => deleteData(developer)}
                    >
                      <DeleteIcon size="small" color="primary">
                        Delete
                      </DeleteIcon>
                    </IconButton>
                  </>
                ) : (
                  ""
                )}
                {filterstatus === "verify" ? (
                  <>
                    <Recipe
                      cardtype="product_pending"
                      value={developer}
                      favorite={setfavitem}
                      item={favitem}
                      showfav={favlist}
                      openprod={handleproduct}
                      closeprod={handleproductclose}
                    ></Recipe>
                    <IconButton
                      aria-label="settings"
                      onClick={() => verifyData(developer)}
                    >
                      <VerifiedUserIcon
                        size="small"
                        color="primary"
                      ></VerifiedUserIcon>
                    </IconButton>
                    <IconButton
                      aria-label="settings"
                      onClick={() => removeData(developer)}
                    >
                      <RemoveCircleOutlineIcon
                        size="small"
                        color="primary"
                      ></RemoveCircleOutlineIcon>
                    </IconButton>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            ))}
          </Grid>
          <br />
        </Container>
      </div>
      <Dialog open={dialog}>
        <DialogContent>
          <div className="space-40" />
          <Typography variant="h5" gutterBottom>
            Submit a new IP/Service
          </Typography>
          <div className="space-40" />
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="standard-select-currency"
                  select
                  required
                  label="Type"
                  className={classes.textField}
                  value={typeR}
                  onChange={e => settypeR(e.target.value)}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  // helperText="Please select type"
                  // margin="normal"
                >
                  {typeIP.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="companyName"
                  name="companyName"
                  label="Company name"
                  value={nameR}
                  onChange={e => setnameR(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="category"
                  name="category"
                  label="Category"
                  value={roleR}
                  onChange={e => setroleR(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                {uploadpic || picid === "" ? (
                  <Upload
                    uid={uidR}
                    store={setpicR}
                    id={picid}
                    setid={setpicid}
                    done={setuploadpic}
                    oldpicid={setoldpicid}
                    setchange={setpicchange}
                  />
                ) : (
                  <Picview
                    image={picR}
                    open={setuploadpic}
                    id={picid}
                    oldpicid={setoldpicid}
                  />
                )}
                {/* <TextField
                required
                id="cardPicture"
                name="cardPicture"
                value={picid}
                label="Card Picture Id"
                fullWidth
              /> */}
              </Grid>
              {/* <Grid item xs={12}>
              <TextField
                required
                id="cardPicture"
                name="cardPicture"
                value={picR}
                onChange={e => setpicR(e.target.value)}
                label="Card Picture Link"
                fullWidth
              />
            </Grid> */}
              <Grid item xs={12}>
                <TextField
                  required
                  id="title"
                  name="title"
                  label="Title"
                  value={titleR}
                  onChange={e => settitle(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="abstract"
                  label="Abstract"
                  placeholder="Placeholder"
                  value={abstractR}
                  onChange={e => setabstract(e.target.value)}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="information"
                  label="Information"
                  placeholder="Placeholder"
                  value={infoR}
                  onChange={e => setinfo(e.target.value)}
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>{" "}
            <div className="space-40" />
            <DialogActions>
              <Button onClick={handleIPClose} color="primary" autoFocus>
                Close
              </Button>{" "}
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </Form>
        </DialogContent>
      </Dialog>
      <DialButton
        add={addcard}
        edit={() => {
          setfilterstatus("edit");
          settopfilter("");
          setIconTabs("1");
        }}
        verify={() => {
          setfilterstatus("verify");
          settopfilter("");
          setIconTabs("1");
        }}
        favour={() => {
          setfilterstatus("favs");
          settopfilter("");
          setIconTabs("1");
        }}
      />
    </>
  );
}

export default Data;
