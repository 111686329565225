import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import LandingPage from "views/product/LandingPage";
// import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "fixed"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={props.dialog}
        // onClose={props.close}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.close}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Description
            </Typography>
            <Button autoFocus color="inherit" onClick={props.close}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <IconButton
          edge="start"
          color="inherit"
          onClick={props.close}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <div>
          <LandingPage info={props.product} premium={props.premium} />
        </div>
      </Dialog>
    </div>
  );
}
