import React from "react"; // useContext
import Firebase from "firebase";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import Carousel from "views/product/Carousel";
import SectionPills from "views/product/SectionPills";
import Cards from "views/product/cards/cards";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";

// reactstrap components
import { Container, Col } from "reactstrap";

// core components
import Footer from "components/Footers/Footer.js";
import { Grid } from "@material-ui/core";

function LandingPage(props) {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    }
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [confirm, setconfirm] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [premium, setpremium] = React.useState({ users: [] });
  const [premstatus, setpremstatus] = React.useState(false);
  const [fetch, setfetch] = React.useState("");
  const mount = React.useRef(premium);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setfetch("default");
    if (mount.current) {
      let ref = Firebase.database().ref("/premium");
      const premium = snapshot => {
        if (snapshot.val()) setpremium(snapshot.val());
      };
      ref.on("value", premium);
      return () => {
        ref.off("value", premium);
      };
    }

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (mount.current === premium) {
    } else {
      writePremium();
      console.log("Settings Data");
    }
    // eslint-disable-next-line
  }, [premium]);

  const writePremium = async () => {
    await Firebase.database()
      .ref("/premium")
      .set(premium);
  };

  React.useEffect(() => {
    const { users } = premium;
    if (fetch === "default") {
      const devIndex = users.findIndex(data => {
        return data.authid === Firebase.auth().currentUser.uid;
      });
      if (devIndex > -1) {
        setpremstatus(true);
      } else {
        setpremstatus(false);
      }
    }
  }, [fetch, premium]);

  const handlePremium = () => {
    setOpen(false);
    const { users } = premium;
    const id = new Date().getTime().toString();
    const authid = Firebase.auth().currentUser.uid;
    users.push({ id, authid });
    setpremium({ users });
    setconfirm(true);
  };

  return (
    <>
      <div
        className="page-header-image"
        style={{
          backgroundImage: "url(" + require("assets/img/code.jpg") + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          maxHeight: "100px",
          minHeight: "100px"
        }}
      />
      <div className="button-container">
        <div className="photo-container">
          {props.info.pic === "" ? (
            <img
              alt="logo"
              src="https://firebasestorage.googleapis.com/v0/b/kachila-a2c91.appspot.com/o/images%2FUntitled%20drawing%20(1).jpg?alt=media&token=a58ce5b6-0224-4181-bdaf-76e9c9aea883"
            />
          ) : (
            <img alt="logo" src={props.info.pic} />
          )}
        </div>
      </div>

      <div>
        <div style={{ textAlign: "justify" }}>
          <Container>
            <div className=" space-40" />

            <Col xs="12">
              <h3 className="title">Company</h3>
              <Typography>{props.info.name}</Typography>

              <h3 className="title">Sector</h3>
              <Typography>{props.info.role}</Typography>

              <div className={classes.root}>
                <h3 className="title">Title</h3>

                <Typography>{props.info.title}</Typography>
                <h3 className="title">Abstract</h3>
                <Typography>{props.info.abstract}</Typography>

                <div onClick={handleExpandClick}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <h3 className="title">Information</h3>
                    </Grid>
                    <Grid item>
                      {" "}
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <div className="space-20" />
                    <Typography>{props.info.info}</Typography>
                  </Collapse>
                </div>
              </div>
            </Col>
            <div className="space-40" />

            <Cards handleOpen={handleClickOpen} />
          </Container>
        </div>
        <SectionPills card={props.info.uid} handleOpen={handleClickOpen} />
        <Footer class={"footer footer-default"} />
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <br />

            <div
              style={{ display: "flex", justifyContent: "center" }}
              id="alert-dialog-title"
            >
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
            </div>
            <DialogTitle
              style={{ display: "flex", justifyContent: "center" }}
              id="alert-dialog-title"
            >
              View Details
            </DialogTitle>
            <DialogContent>
              {premstatus === false ? (
                <div style={{ textAlign: "justify" }}>
                  {" "}
                  <p>
                    Some of these features are currently unavailable to you.
                  </p>
                  <p>
                    Kachila is currently working with leading defence and
                    consumer technology development companies and has to scale
                    in size smartly and control the flow of information.
                  </p>
                  <p>
                    To be able to access additional information on various IPs,
                    contacts, location and details you need Premium Access and
                    may have to pay a subscription. If you are interested,
                    please click the 'request' button and we will get back to
                    you.
                  </p>
                </div>
              ) : (
                <p>
                  We have already recieved your request for Premium Access and
                  we are currently working towards it.
                </p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              {premstatus === false ? (
                <Button onClick={handlePremium} color="primary" autoFocus>
                  Request Access
                </Button>
              ) : (
                ""
              )}
            </DialogActions>
          </Dialog>
          <Dialog
            open={confirm}
            onClose={() => setconfirm(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <br />

            <div
              style={{ display: "flex", justifyContent: "center" }}
              id="alert-dialog-title"
            >
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
            </div>
            <DialogTitle
              style={{ display: "flex", justifyContent: "center" }}
              id="alert-dialog-title"
            >
              View Details
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Thanks, your request has been recieved and we will get back to
                you soon.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setconfirm(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
