import React from "react";
import Grid from "@material-ui/core/Grid";

// reactstrap components
import { Container } from "reactstrap";
import Privacy from "views/terms/privacy";
import TOS from "views/terms/tos";

function TransparentFooter(props) {
  const [privacy, setPrivacy] = React.useState(false);
  const [terms, setterms] = React.useState(false);

  const privacyopen = () => {
    setPrivacy(true);
  };

  const privacyclose = () => {
    setPrivacy(false);
  };

  const termsopen = () => {
    setterms(true);
  };

  const termsclose = () => {
    setterms(false);
  };
  return (
    <div>
      <footer className={props.class}>
        {/* className="footer" data-background-color="black" */}
        {/* "footer footer-default" */}
        {/* "footer" */}
        <Container>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <nav>
                <ul>
                  <li>
                    <a href="#kachila">Kachila</a>
                  </li>
                  <li>
                    <div
                      onClick={e => {
                        e.preventDefault();
                        privacyopen();
                      }}
                    >
                      <a href="#privacy">Privacy</a>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={e => {
                        e.preventDefault();
                        termsopen();
                      }}
                    >
                      <a href="#terms">Terms of Service</a>
                    </div>
                  </li>
                </ul>
              </nav>
            </Grid>
            <Grid item xs={12} md={6}>
              {" "}
              <div className="copyright" id="copyright">
                © {new Date().getFullYear()}, Kachila{" "}
                <a href="#kachila">(Pty) Ltd.</a>{" "}
                {/* {"Created by: "}
            <i class="fab fa-linkedin" style={{ fontSize: "27" }}></i> */}
              </div>
            </Grid>
          </Grid>
        </Container>
      </footer>
      <Privacy open={privacy} handleClose={privacyclose} />
      <TOS open={terms} handleClose={termsclose} />
    </div>
  );
}

export default TransparentFooter;
