import clsx from "clsx";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Firebase from "firebase";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import FilterIcon from "@material-ui/icons/FilterList";
// import SortIcon from "@material-ui/icons/SortByAlpha";
import PublishIcon from "@material-ui/icons/Publish";
import EditIcon from "@material-ui/icons/Edit";
import FavoriteIcon from "@material-ui/icons/Favorite";
// import SearchIcon from "@material-ui/icons/Search";
// import { ClickAwayListener } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    // width: "100%"
  },
  // controls: {
  //   margin: theme.spacing(3)
  // },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3)
    // height: 380
  },

  speedDial: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3)
  }
}));

export default function SpeedDials(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const speedDialClassName = clsx(classes.speedDial);

  return (
    <div className={classes.root}>
      <div className={classes.exampleWrapper}>
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={speedDialClassName}
          // hidden={hidden}
          icon={<SpeedDialIcon />}
          onBlur={handleClose}
          onClick={handleClick}
          onClose={handleClose}
          onFocus={handleOpen}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          open={open}
          direction="up"
        >
          <SpeedDialAction
            key="Upload"
            icon={<PublishIcon />}
            tooltipPlacement="right"
            tooltipTitle="Upload"
            onClick={props.add}
          />
          <SpeedDialAction
            key="Edit"
            icon={<EditIcon />}
            tooltipPlacement="right"
            tooltipTitle="Edit / Delete"
            onClick={props.edit}
          />
          {Firebase.auth().currentUser.uid ===
          "YktJ5sTUD0MI0qZ8lF7iiwyW8fC3" ? (
            <SpeedDialAction
              key="Filter"
              icon={<FilterIcon />}
              tooltipPlacement="right"
              onClick={props.verify}
              tooltipTitle="Filter"
            />
          ) : (
            ""
          )}

          <SpeedDialAction
            key="Fav"
            icon={<FavoriteIcon />}
            tooltipPlacement="right"
            tooltipTitle="Favorites"
            onClick={props.favour}
          />
        </SpeedDial>
      </div>
    </div>
  );
}
