import React from "react";
import Firebase from "firebase";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function FormDialog(props) {
  const [message, setmessage] = React.useState("");

  const handleClose = () => {
    props.set(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const id = new Date().getTime().toString();
    const authid = Firebase.auth().currentUser.uid;
    await Firebase.database().ref("/report").push({ id, authid, message });
    setmessage("");
    handleClose();
  };

  return (
    <div>
      <Dialog open={props.open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Feedback</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Your feedback or suggestions are very important to us.
            </DialogContentText>
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              label="Feedback"
              type="text"
              value={message}
              onChange={(e) => setmessage(e.target.value)}
              fullWidth
            />
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
