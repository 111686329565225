import React from "react";
import app from "views/auth/base.js";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import HistoryIcon from "@material-ui/icons/History";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

// reactstrap components
import {
  // Card,
  // CardHeader,
  // CardBody,
  // NavItem,
  // NavLink,
  // Nav,
  // TabContent,
  // TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function Tabs() {
  return (
    <>
      {/* <Col className="ml-auto mr-auto" md="10" xl="6"> */}
      {/* <p className="category">Tabs with Icons on Card</p> */}

      <div className="space-40" />
      <Container>
        <Row>
          <Col xs="3" style={{ textAlign: "center" }}>
            {/* <i
                  class="fas fa-at fa-2x"
                  // style={{ color: "#fe9200" }}
                  style={{ color: "#f96332" }}
                /> */}
            <MailOutlineIcon style={{ fontSize: 28 }} />
          </Col>
          <Col>
            <h6 style={{ fontSize: 14 }}>Email</h6>
            <Typography variant="body2" color="textSecondary" component="p">
              {app.auth().currentUser.emailVerified && "Verified"}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {!app.auth().currentUser.emailVerified && "Not Verified"}
            </Typography>
          </Col>
          <Col xs="2"></Col>
        </Row>
        <br />
        <Row>
          <Col xs="3" style={{ textAlign: "center" }}>
            <AccessTimeIcon style={{ fontSize: 28 }} />
            {/* <i
                  class="far fa-clock fa-2x"
                  // style={{ color: "#fe9200" }}
                  style={{ color: "#f96332" }}
                /> */}
          </Col>
          <Col>
            <h6 style={{ fontSize: 14 }}>Last Signed-In</h6>
            <Typography variant="body2" color="textSecondary" component="p">
              {moment(app.auth().currentUser.metadata.lastSignInTime).format(
                "LLLL"
              )}
            </Typography>
          </Col>
          <Col xs="2"></Col>
        </Row>
        <br />
        <Row>
          <Col xs="3" style={{ textAlign: "center" }}>
            {/* <i
                  class="far fa-calendar-alt fa-2x"
                  // style={{ color: "#fe9200" }}
                  style={{ color: "#f96332" }}
                /> */}
            <HistoryIcon style={{ fontSize: 30 }} />
          </Col>
          <Col>
            <h6 style={{ fontSize: 14 }}>Created</h6>
            <Typography variant="body2" color="textSecondary" component="p">
              {moment(app.auth().currentUser.metadata.creationTime).format(
                "LLLL"
              )}
            </Typography>
          </Col>
          <Col xs="2"></Col>
        </Row>
      </Container>
    </>
  );
}

export default Tabs;
