import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import Container from "@material-ui/core/Container";
// import Typography from "./Typography";
// import CardContent from "@material-ui/core/CardContent";
// import Collapse from "@material-ui/core/Collapse";
// import Recipe from "./Categories/Recipe";
import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";

// import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";

// import CardMedia from "@material-ui/core/CardMedia";
import ProductTile from "./Categories/ProductTile";
import ImageDefence from "assets/img/products/defence.jpg";
import ImageHealth from "assets/img/products/health.jpg";

// import ImageMotorsport from "assets/img/products/motorsport.jpg";
import ImageTele from "assets/img/products/tele.jpg";
// import ImageMachine from "assets/img/products/machine.jpg";
import ImageBusiness from "assets/img/products/business.jpg";
import ImageTechnology from "assets/img/products/technology.jpg";
import ImageData from "assets/img/products/data.jpg";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexWrap: "wrap",
  },
  imageWrapper: {
    position: "relative",
    display: "block",
    padding: 0,
    borderRadius: 0,
    height: "40vh",
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
      height: 100,
    },
    "&:hover": {
      zIndex: 1,
    },
    "&:hover $imageBackdrop": {
      opacity: 0.15,
    },
    "&:hover $imageMarked": {
      opacity: 0,
    },
    "&:hover $imageTitle": {
      border: "4px solid currentColor",
    },
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
});

function ProductCategories(props) {
  const { classes } = props;

  const images = [
    {
      id: "1",
      url: ImageDefence,
      title: "Defence",
      description1:
        "Kachila is part of the community which helps the Defence Industry to manage technology development, IPs, expand present relations, generate new business opportunities and establish partnerships.",
      description2:
        "Kachila is also involved in future technology development strategies and long term obsolescence management of the Tracking Radar (TR) Systems and analysis of air defence and surface warfare engagements by modelling and simulation.",
    },
    {
      id: "2",
      url: ImageHealth,
      title: "Health",
      description1:
        "Kachila is currently involved with neurology institutions and Health Departments in developing a Machine Learning driven database to classify symptoms, detection of diseases at early stages and reduce legal cost due to misdiagnosis and malpractices.",
      description2:
        "The technology is being built on Intellectual Properties from the defence sector used for threat classification under a common balance sheet to reduce cost and stimulate growth on both sectors.",
    },
    // {
    //   id: "2",
    //   url: ImageMotorsport,
    //   title: "Motorsport",
    //   description1:
    //     "Kachila is currently involved with motorsport and a leading defence company in developing a deep learning model for object detection, data learning and threat classification using 4K cameras and sensors.",
    //   description2:
    //     "The Intellectual Property is being developed is to improve race driver education and safety with potential expansion onto training and education of personnel in the aerospace and defence sector. This includes building a strategy for future, product roadmaps and taking products and services to an international stage."
    // }
  ];

  const images2 = [
    {
      id: "3",
      url: ImageData,
      title: "Data",
      description1:
        "Kachila has initiated various Non-profit initiative to build data infrastructure and digitisation of data for industries in the developing countries.",
      description2:
        "As developing countries often lack in funds and capabilities, Kachila is strategising to use existing IPs as to bring the cost to a minimum, while developing a state of the data infrastructure in areas that lack basic infrastructure.",
    },
    {
      id: "4",
      url: ImageTele,
      title: "Telecommunications",
      description1:
        "Kachila is currently working with industry professionals in the new-age of cloud, server datacenter and video broadcast/delivery infrastructure solutions. From greenfield datacenter builds to low latency live and on-demand content streaming, these platforms can be deployed on the cloud or custom physical locations for easy network, storage, compute use cases as well as video delivery via TV, web, and mobile devices.",
      description2:
        "Encrypted data transfer and storage with state-of-the-art server farms ensure a secure and downtime free delivery of content. Data analytics and insight such as object detection, usage patterns, add further decision making tools for any business to optimize and grow.",
    },
  ];

  const images3 = [
    {
      id: "5",
      url: ImageBusiness,
      title: "Business",
      description1:
        "Most of Kachila's involvement is in research into building a new business model for companies (particularly state-owned) operating in the defence sector to be self-sustainable during defence budget cuts and fluctuations.",
      description2:
        "The interest shown from the defence sector in the platform has generated requests to perform study into creating new markets for existing IPs, identifying redundant technologies and finding a new approach to traditional acquisition and mergers of companies to be able to share IPs.",
    },
    {
      id: "6",
      url: ImageTechnology,
      title: "Technology",
      description1:
        "Kachila has invested in keeping tabs on the maturity of defence technologies, feasibility studies, future technology and market opportunities.",
      description2:
        "Being well informed, Kachila is currently leading companies in the various sector on starting technoscientific projects, utilise existing IPs, performing concept evaluations and develop systems through trusted and verified defence partners and service providers.",
    },
  ];

  return (
    <div className={classes.cardGrid} maxwidth="md">
      <div className="space-40" />
      <div className="space-40" />
      {/* End hero unit */}
      <Container>
        <Grid container spacing={0}>
          {images.map((card) => (
            <Grid item key={card.id} xs={12} sm={6} md={6}>
              <ProductTile
                title={card.title}
                width="100%"
                image={card.url}
                description1={card.description1}
                description2={card.description2}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={0}>
          {images2.map((card) => (
            <Grid item key={card.id} xs={12} sm={6} md={6}>
              <ProductTile
                title={card.title}
                width="100%"
                image={card.url}
                description1={card.description1}
                description2={card.description2}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={0}>
          {images3.map((card) => (
            <Grid item key={card.id} xs={12} sm={6} md={6}>
              <ProductTile
                title={card.title}
                width="100%"
                image={card.url}
                description1={card.description1}
                description2={card.description2}
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      <div className="space-40" />
      <div className="space-40" />
    </div>
  );
}

ProductCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCategories);
