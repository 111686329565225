import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footers/Footer";
import Database from "views/database/data";
import SignedInNavbar from "components/Navbars/SignedInNavbar";
import UserDetails from "views/settings/UserDetails";
import Feedback from "views/settings/feedback";
// import Firebase from "firebase";
// import { Button } from "reactstrap";
// import User from "views/settings/User";
// import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    // padding: theme.spacing(2),
    marginTop: "auto",
    backgroundColor: "white",
  },
}));

export default function StickyFooter(props) {
  const classes = useStyles();
  const [userdialog, setuserdialog] = React.useState(false);
  const [addverify, setaddverify] = React.useState(false);
  const [feedback, setfeedback] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("home-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  // const writeRandom = async () => {
  //   const random = { address: "Blah", city: "Another Blah" };
  //   random.another = "hello";
  //   await Firebase.database()
  //     .ref("/usercustom/" + Firebase.auth().currentUser.uid)
  //     .set(random);
  // };

  // const Read = async () => {
  //   let ref = Firebase.database().ref(
  //     "/usercustom/" + Firebase.auth().currentUser.uid
  //   );
  //   const settings = snapshot => {
  //     if (snapshot.val()) setsettings(snapshot.val());
  //   };
  //   ref.on("value", settings);
  //   return () => {
  //     ref.off("value", settings);
  //   };
  // };

  // const handleRead = () => {
  //   Read();
  //   console.log(settings.city);
  // };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <UserDetails
        dialog={userdialog}
        handle={setuserdialog}
        verify={setaddverify}
      />
      <SignedInNavbar handle={setuserdialog} feedback={setfeedback} />
      <Feedback open={feedback} set={setfeedback} />
      <Database verify={addverify} addupdate={setuserdialog} />
      {/* <Button onClick={writeRandom}>Random</Button> */}
      <footer className={classes.footer}>
        <Footer class={"footer footer-default"} />
      </footer>
    </div>
  );
}
