import React from "react";
import Firebase from "firebase";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// reactstrap components
import {
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Input,
  Container,
  Card,
  CardHeader,
  CardBody
} from "reactstrap";

// core components

function Download() {
  const [first, setfirst] = React.useState("");
  const [last, setlast] = React.useState("");
  const [email, setemail] = React.useState("");
  const [message, setmessage] = React.useState("");
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [dialog, setdialog] = React.useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    const date = new Date().getTime().toString();
    await Firebase.database()
      .ref("/contactus")
      .push({ date, first, last, email, message });
    setdialog(true);

    // setfeedback("Thank You " + first + ", Your message has been received.");
  };

  const closedialog = () => {
    setdialog(false);
    setfirst("");
    setlast("");
    setemail("");
  };

  return (
    <>
      <div
        className="section section-download clear-filter"
        filter-color="blue"
        style={{
          backgroundImage: "url(" + require("assets/img/glenn.jpg") + ")",
          backgroundSize: "cover"
          // minHeight: "700px"
        }}
        id="contact-us"
      >
        <Row>
          <Col md="2" />
          <Col md="4">
            <Container>
              <h2 className="title" style={{ color: "white" }}>
                Get in Touch
              </h2>
              <h5 style={{ color: "white" }}>
                Need more information? Want to work with us?
              </h5>
              <h5 style={{ color: "white" }}>
                Your project is very important to us.{" "}
              </h5>
            </Container>
          </Col>

          <Col md="4">
            <Container>
              <Card>
                <Form onSubmit={handleSubmit}>
                  <CardHeader className="text-center">
                    <h4>Contact Us</h4>
                  </CardHeader>
                  <CardBody>
                    <Container>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          name="text"
                          placeholder="First Name..."
                          type="text"
                          value={first}
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                          onChange={e => setfirst(e.target.value)}
                          required={true}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          name="text"
                          placeholder="Last Name..."
                          type="text"
                          value={last}
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                          onChange={e => setlast(e.target.value)}
                          required={true}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (emailFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          name="email"
                          placeholder="Email..."
                          type="email"
                          value={email}
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                          onChange={e => setemail(e.target.value)}
                          required={true}
                        />
                      </InputGroup>

                      {/* <div className={classes.margin}>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item>
                        <AccountCircle />
                      </Grid>
                      <Grid item>
                        <TextField id="input-with-icon-grid" label="Name" />
                      </Grid>
                    </Grid>
                  </div> */}
                      <div className="textarea-container">
                        <Input
                          cols="80"
                          name="text"
                          placeholder="Type a message..."
                          rows="5"
                          type="textarea"
                          value={message}
                          onChange={e => setmessage(e.target.value)}
                          required={true}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                        className="send-button"
                      >
                        <Button className="btn-round" color="primary" size="lg">
                          Send Message
                        </Button>
                      </div>
                    </Container>
                  </CardBody>
                </Form>
              </Card>
            </Container>
          </Col>
        </Row>

        <Dialog
          open={dialog}
          onClose={closedialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Contact Us"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Thank You"} {first}
              {", Your message has been received."}
            </DialogContentText>
          </DialogContent>
          {/* <Container>
            <Divider />
          </Container> */}

          <DialogActions>
            <Button onClick={closedialog} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default Download;
