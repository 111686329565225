import React from "react";

// reactstrap components
import { Row, Col, Container } from "reactstrap";
import Typography from "./Typography";
// core components

function Explain() {
  return (
    <>
      {/* <div
        className="section section-download blue-filter"
        style={{
          backgroundImage: "url(" + require("assets/img/kit.jpg") + ")",
          backgroundSize: "cover"
          // minHeight: "700px"
        }}
      > */}
      <div
        className="section section-download blue-filter"
        filter-color="blue"
        style={{
          backgroundImage: "url(" + require("assets/img/ethan.jpg") + ")",
          backgroundSize: "cover",
          minHeight: "980px"
        }}
        id="about-section"
      >
        <Container>
          <Row>
            <Col md="4"></Col>
            <Col md="1"></Col>
            <Col md="7">
              <div className="px-md-5" style={{ textAlign: "justify" }}>
                {/* <hr className="line-success" style={{ color: "white" }} /> */}
                {/* <h2 className="title" style={{ color: "white" }} align="center">
                The Concept
              </h2> */}

                <Typography
                  variant="h4"
                  marked="center"
                  align="center"
                  component="h2"
                  style={{ color: "white" }}
                >
                  The Idea
                </Typography>
                <br></br>
                <br></br>
                <h5 style={{ color: "white" }}>
                  Kachila was born out of an on-going study on the
                  commercialisation of military and aerospace technology at the
                  Faculty of Military Science, Unversity of Stellenbosch.
                </h5>
                <h5 style={{ color: "white" }}>
                  Based on experience gained from working in State-Owned
                  Companies (SOCs) on managing complex development projects,
                  sub-contractors and negotiations of contracts on behalf of the
                  Department of Defence ...
                </h5>

                <h5 style={{ color: "white" }}>
                  {" "}
                  Kachila is focused on creating new markets and additional
                  revenue streams, to help sustain growth and technology
                  development while ensuring defence forces can perform its duty
                  in an optimum role during fluctuating defence budgets.
                </h5>
                <h5 style={{ color: "white" }}>
                  {" "}
                  Kachila aims to connect the commercial sector companies that
                  are looking to expand their digital and technology footprint
                  with the right technology and companies with ease and
                  assurance of quality work.
                </h5>

                <h5 style={{ color: "white" }}>
                  Kachila has developed a database of technologies, intellectual
                  properties (IP), suppliers and contractors to provide:
                </h5>

                <ul
                  className="list-unstyled mt-5"
                  style={{ textAlign: "left", color: "white" }}
                >
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-success mb-2">
                        <i
                          className="fas fa-laptop-code fa-1x"
                          // style={{ color: "#fe9200" }}
                          style={{ color: "#f96332" }}
                        />
                      </div>
                      <div className="ml-3">
                        <h6>Analytics on existing IPs and its potential</h6>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-success mb-2">
                        <i
                          className="fas fa-fighter-jet fa-1x"
                          // style={{ color: "#fe9200" }}
                          style={{ color: "#f96332" }}
                        />
                      </div>
                      <div className="ml-3">
                        <h6>
                          Brokering of IPs between defence and commercial sector
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-success mb-2">
                        <i
                          className="fas fa-cogs fa-1x"
                          // style={{ color: "#fe9200" }}
                          style={{ color: "#f96332" }}
                        />
                      </div>
                      <div className="ml-3">
                        <h6>
                          Managing technology development through trusted and
                          verified defence partners
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <div className="icon icon-success mb-2">
                        <i
                          className="fas fa-cogs fa-1x"
                          // style={{ color: "#fe9200" }}
                          style={{ color: "#f96332" }}
                        />
                      </div>
                      <div className="ml-3">
                        <h6>
                          Creating new market opportunities based on a data
                          driven approach
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* <Row style={{ textAlign: "center" }}>
              <Col>
                <img
                  alt="..."
                  className="n-logo"
                  src={require("assets/img/milsci.png")}
                  style={{ height: "150px" }}
                />
                <br />
                <p>Faculty of Military Science</p>
              </Col>
              <Col>
                <img
                  alt="..."
                  className="n-logo"
                  src={require("assets/img/stellen2.jpeg")}
                  style={{ height: "150px" }}
                />
              </Col>
            </Row> */}
            </Col>
          </Row>
        </Container>

        {/* <Container>
          <Row className="justify-content-center">
            <Col lg="12">
              <h1 className="text-center">Your best benefit</h1>
              <Row className="row-grid justify-content-center">
                <Col lg="3">
                  <div className="info">
                    <div className="icon icon-primary">
                      <i className="tim-icons icon-money-coins" />
                    </div>
                    <h4 className="info-title">Low Commission</h4>
                    <hr className="line-primary" />
                    <p>
                      Divide details about your work into parts. Write a few
                      lines about each one. A paragraph describing a feature
                      will.
                    </p>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="info">
                    <div className="icon icon-warning">
                      <i className="tim-icons icon-chart-pie-36" />
                    </div>
                    <h4 className="info-title">High Incomes</h4>
                    <hr className="line-warning" />
                    <p>
                      Divide details about your product or agency work into
                      parts. Write a few lines about each one. A paragraph
                      describing feature will be a feature.
                    </p>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="info">
                    <div className="icon icon-success">
                      <i className="tim-icons icon-single-02" />
                    </div>
                    <h4 className="info-title">Verified People</h4>
                    <hr className="line-success" />
                    <p>
                      Divide details about your product or agency work into
                      parts. Write a few lines about each one. A paragraph
                      describing be enough.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container> */}
      </div>
    </>
  );
}

export default Explain;
