import React from "react";
// import { withRouter } from "react-router";
// import { Link } from "react-router-dom";
import {
  useCallback,
  useContext
  // useState
} from "react";
import {
  // withRouter,
  Redirect
} from "react-router";
import app from "views/auth/base.js";
import { AuthContext } from "views/auth/Auth.js";

import "firebase/auth";
import firebase from "firebase";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  // InputGroupAddon,
  // InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
  // Modal,
  // ModalBody
} from "reactstrap";

import { Alert } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footers/Footer.js";

function LoginPage({ history }) {
  // const [modal, setModal] = useState(false);
  const [alert1, setAlert1] = React.useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [page, setpage] = React.useState("login");

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const { currentUser } = useContext(AuthContext);

  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      if (page === "login") {
        const { email, password } = event.target.elements;
        try {
          await app
            .auth()
            .signInWithEmailAndPassword(email.value, password.value);

          history.push("/login");
          setAlert1(true);
        } catch (error) {
          alert(error);
        }
      }
      if (page === "signup") {
        const { email, password } = event.target.elements;
        const actionCodeSettings = {
          url: "https://www.kachilagroup.com/login",
          handleCodeInApp: true
        };
        try {
          await app
            .auth()
            .createUserWithEmailAndPassword(email.value, password.value);

          await app
            .auth()
            .currentUser.sendEmailVerification(actionCodeSettings);
          alert("A Verification Link has been sent to your Email.");
          history.push("/login");
          setpage("login");
        } catch (error) {
          alert(error);
        }
      }
    },
    [history, page]
  );

  const handleVerify = useCallback(
    async event => {
      event.preventDefault();

      const actionCodeSettings = {
        url: "https://www.kachilagroup.com/login",
        handleCodeInApp: true
      };
      try {
        await app.auth().currentUser.sendEmailVerification(actionCodeSettings);
        alert("A new link has been sent to your Email.");
        setAlert1(false);
        history.push("/login");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const handleReset = useCallback(
    async event => {
      event.preventDefault();
      const { email } = event.target.elements;
      try {
        await app.auth().sendPasswordResetEmail(email.value);
        alert("A reset link has been sent to your Email.");
        history.push("/login");
        setpage("login");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  if (currentUser) {
    if (app.auth().currentUser.emailVerified) {
      return <Redirect to="/home" />;
    } else {
      //Do nothing
    }
  }

  // core components

  return (
    <>
      <ExamplesNavbar />
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/k-logo-1.svg") + ")"
          }}
        />
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="10">
              <Card className="card-login card-plain">
                <CardHeader className="text-center">
                  <div className="logo-container">
                    <img
                      alt="..."
                      src={require("assets/img/k-logo-1.svg")}
                      options={{ mode: "min" }}
                      style={{ height: "80px", width: "160px" }}
                      className="size"
                    />
                  </div>
                  <Row />
                  {page === "login" ? <h6>Sign in with</h6> : <div />}
                  {page === "signup" ? <h6>Sign up with</h6> : <div />}
                  {page === "reset" ? <h6>Reset Password</h6> : <div />}

                  <Button
                    className="btn-neutral btn-round"
                    color="info"
                    onClick={() => {
                      const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
                      firebase.auth().signInWithPopup(GoogleAuthProvider);
                    }}
                  >
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                      style={{ height: "35px", width: "35px" }}
                    />
                    Google
                  </Button>
                </CardHeader>

                <CardBody>
                  <p>{"   Or using credentials"}</p>
                  {page !== "reset" ? (
                    <Form onSubmit={handleLogin}>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          name="email"
                          type="email"
                          placeholder="Email..."
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          name="password"
                          type="password"
                          placeholder="Password..."
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                        />
                      </InputGroup>
                      <InputGroup>
                        {page === "login" ? (
                          <Button
                            block
                            className="btn-round"
                            color="info"
                            size="lg"
                          >
                            Sign In
                          </Button>
                        ) : (
                          <div />
                        )}
                        {page === "signup" ? (
                          <Button
                            block
                            className="btn-neutral btn-round"
                            color="info"
                            size="lg"
                          >
                            Get Started
                          </Button>
                        ) : (
                          <div />
                        )}
                      </InputGroup>
                      <div className="pull-left">
                        <h6>
                          {page === "login" ? (
                            <a
                              className="link"
                              href="#kachila"
                              link=""
                              onClick={() => setpage("signup")}
                            >
                              Sign Up
                            </a>
                          ) : (
                            ""
                          )}

                          {page === "signup" ? (
                            <a
                              className="link"
                              href="#kachila"
                              link=""
                              onClick={() => setpage("login")}
                            >
                              Sign In
                            </a>
                          ) : (
                            ""
                          )}
                        </h6>
                      </div>
                      <div className="pull-right">
                        <h6>
                          <a
                            className="link"
                            href="#kachila"
                            onClick={() => setpage("reset")}
                          >
                            Need Help?
                          </a>
                        </h6>
                      </div>
                    </Form>
                  ) : (
                    <Form onSubmit={handleReset}>
                      <CardBody>
                        <InputGroup
                          className={
                            "no-border input-lg" +
                            (firstFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            name="email"
                            type="email"
                            placeholder="Email..."
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                          />
                        </InputGroup>

                        <InputGroup>
                          <Button
                            block
                            className="btn-round"
                            color="info"
                            size="lg"
                          >
                            Reset Password
                          </Button>
                        </InputGroup>

                        <div className="pull-left">
                          <h6>
                            <a
                              className="link"
                              href="#kachila"
                              onClick={() => setpage("signup")}
                            >
                              Sign Up
                            </a>
                          </h6>
                        </div>
                        <div className="pull-right">
                          <h6>
                            <a
                              className="link"
                              href="#kachila"
                              onClick={() => setpage("login")}
                              // to="/ResetPassword"
                            >
                              Login
                            </a>
                          </h6>
                        </div>
                      </CardBody>
                      <CardFooter className="text-center"></CardFooter>
                    </Form>
                  )}
                </CardBody>
                <CardFooter className="text-center"></CardFooter>
              </Card>
            </Col>

            <Alert color="warning" isOpen={alert1}>
              <Container>
                <div className="alert-icon">
                  <i className="now-ui-icons ui-1_bell-53"></i>
                </div>
                <button
                  type="button"
                  className="close"
                  onClick={() => setAlert1(false)}
                >
                  <span aria-hidden="true">
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </span>
                </button>
                <strong>Email not verified!</strong> Please click the link sent
                to your email address.
                <h6>
                  <a className="link" href="/login" onClick={handleVerify}>
                    Resend Link?
                  </a>
                </h6>
              </Container>
            </Alert>
          </Container>
        </div>
        <Footer class={"footer"} />
      </div>
    </>
  );
}

export default LoginPage;
