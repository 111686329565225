/*eslint-disable*/
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components

function IndexHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/mountain.jpg") + ")",
          }}
          ref={pageHeader}
        />
        {/* <div className="fog-low">
          <img alt="..." src={require("assets/img/fog-low.png")} />
        </div>
        <div className="fog-low right">
          <img alt="..." src={require("assets/img/fog-low.png")} />
        </div> */}
        <Container>
          <div className="content-center brand">
            <img
              alt="..."
              className="n-logo"
              src={require("assets/img/k-logo-1.svg")}
              style={{ height: "90px" }}
            />
            <h1 className="h1-seo">Intellectual Property Platform</h1>
            {/* <h3>Register. Advertise. Shop IPs.</h3> */}
          </div>
        </Container>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")",
          }}
        />
        <div className="section section-images">
          <Container>
            <Row>
              <Col md="10">
                <div className="hero-images-container">
                  <img alt="..." src={require("assets/img/gripen.png")} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
