/* eslint-disable no-unused-vars */
import React, { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
// import LogIn from "components/Modal/LogIn.js";
// import { setVisibilty } from "components/Modal/LogIn.js";
import app from "views/auth/base.js";
import { AuthContext } from "views/auth//Auth.js";
import firebase from "firebase";

import {
  // withRouter,
  Redirect
} from "react-router";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  NavItem,
  NavLink,
  Col,
  Nav,
  TabContent,
  TabPane
} from "reactstrap";

/* eslint-enable no-unused-vars */

// core components

function SignUp({ history }) {
  // const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  // const [pills, setPills] = React.useState("2");

  const handleSignUp = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      const actionCodeSettings = {
        url: "https://www.kachilagroup.com/login",
        handleCodeInApp: true
      };
      try {
        await app
          .auth()
          .createUserWithEmailAndPassword(email.value, password.value);

        await app.auth().currentUser.sendEmailVerification(actionCodeSettings);
        alert("A Verification Link has been sent to your Email.");
        history.push("/login");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const handleGoogleButton = useCallback(
    async event => {
      event.preventDefault();
      const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
      // const actionCodeSettings = {
      //   url: "https://www.kachilagroup.com/login",
      //   handleCodeInApp: true
      // };
      try {
        await firebase.auth().signInWithPopup(GoogleAuthProvider);
        // alert("Signed In");
        history.push("/login");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  // const { currentUser } = useContext(AuthContext);

  // const handleGoogleButton = () => {
  //   const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
  //   firebase.auth().signInWithPopup(GoogleAuthProvider);
  //   setsigningoogle(true);
  // };

  // if (currentUser) {
  //   if (app.auth().currentUser.emailVerified && signingoogle) {
  //     setsigningoogle(false);
  //     return <Redirect to="/home" />;
  //   } else {
  //     //Do nothing
  //   }
  // }

  // const { currentUser } = useContext(AuthContext);

  // if (currentUser) {
  //   return <Redirect to="/login" />;
  // }

  return (
    <>
      <div
        className="section section-signup"
        style={{
          backgroundImage: "url(" + require("assets/img/sign.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "800px"
        }}
        id="signup-section"
      >
        <Container>
          <Row>
            <Card
              className="card-signup text-center"
              data-background-color="blue"
            >
              <Form onSubmit={handleSignUp}>
                <h4>Sign Up</h4>
                <CardHeader className="text-center">
                  <Row />
                  <h6>with</h6>
                  <Button
                    className="btn-neutral btn-round"
                    color="info"
                    onClick={handleGoogleButton}
                  >
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                      style={{ height: "35px", width: "35px" }}
                    />
                    Google
                  </Button>
                </CardHeader>
                <p>{"   Or using credentials"}</p>
                <CardBody>
                  <InputGroup
                    className={
                      "no-border input-lg" +
                      (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <Input
                      name="email"
                      placeholder="Email..."
                      type="email"
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                    />
                  </InputGroup>
                  <InputGroup
                    className={
                      "no-border input-lg" +
                      (emailFocus ? " input-group-focus" : "")
                    }
                  >
                    <Input
                      name="password"
                      type="password"
                      placeholder="Password..."
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                    />
                  </InputGroup>
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    className="btn-neutral btn-round"
                    color="info"
                    type="submit"
                    size="lg"
                  >
                    Get Started
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </Row>
          <div className="col text-center" />
        </Container>

        <div className="col text-center">
          {/* <Button
            className="btn-round btn-white"
            color="default"
            outline
            size="lg"
            to="/login/"
          >
            Sign In
          </Button> */}
        </div>
      </div>
    </>
  );
}
export default withRouter(SignUp);
