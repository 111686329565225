import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import Index from "views/pages/Index.js";
import Login from "views/pages//LoginPage";
import Home from "views/pages//Home";
import { AuthProvider } from "views/auth//Auth";
import PrivateRoute from "views/auth//PrivateRoute";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Route exact path="/" render={(props) => <Index {...props} />} />
        <Route eaxct path="/login" render={(props) => <Login {...props} />} />
        <PrivateRoute exact path="/home" component={Home}></PrivateRoute>
        <Redirect to="/home" />
        {/* <Redirect from="/" to="/home" /> */}
      </Router>
    </AuthProvider>
  );
};

export default App;
